const en = {
  search_field: 'Search field',
  field_not_found: 'Field not found',
  clear: 'Clear',
  align: 'Align',
  add_all_required_fields_to_continue: 'Add all required fields to continue',
  left: 'Left',
  right: 'Right',
  center: 'Center',
  description: 'Description',
  display_title: 'Display title',
  with_logo: 'With logo',
  unchecked: 'Unchecked',
  equal: 'Equal',
  not_equal: 'Not equal',
  contains: 'Contains',
  does_not_contain: 'Does not contain',
  not_empty: 'Not empty',
  empty: 'Empty',
  select_field_: 'Select field...',
  select_value_: 'Select value...',
  remove_condition: 'Remove condition',
  are_you_sure: 'Are you sure?',
  sign_yourself: 'Sign Yourself',
  set_signing_date: 'Set signing date',
  send: 'Send',
  remove: 'Remove',
  edit: 'Edit',
  settings: 'Settings',
  up: 'Up',
  down: 'Down',
  checked: 'Checked',
  save: 'Save',
  cancel: 'Cancel',
  any: 'Any',
  drawn: 'Drawn',
  formula: 'Formula',
  typed: 'Typed',
  draw_field_on_the_document: 'Draw {field} field on the document',
  click_to_upload: 'Click to upload',
  or_drag_and_drop_files: 'or drag and drop files',
  uploading: 'Uploading',
  processing_: 'Processing...',
  add_pdf_documents_or_images: 'Add PDF documents or images',
  add_documents_or_images: 'Add documents or images',
  required: 'Required',
  default_value: 'Default value',
  format: 'Format',
  read_only: 'Read-only',
  page: 'Page',
  draw_new_area: 'Draw New Area',
  copy_to_all_pages: 'Copy to All Pages',
  add_option: 'Add option',
  option: 'Option',
  condition: 'Condition',
  first_party: 'First Party',
  second_party: 'Second Party',
  third_party: 'Third Party',
  fourth_party: 'Fourth Party',
  fifth_party: 'Fifth Party',
  sixth_party: 'Sixth Party',
  seventh_party: 'Seventh Party',
  eighth_party: 'Eighth Party',
  ninth_party: 'Ninth Party',
  tenth_party: 'Tenth Party',
  eleventh_party: 'Eleventh Party',
  twelfth_party: 'Twelfth Party',
  thirteenth_party: 'Thirteenth Party',
  fourteenth_party: 'Fourteenth Party',
  fifteenth_party: 'Fifteenth Party',
  sixteenth_party: 'Sixteenth Party',
  seventeenth_party: 'Seventeenth Party',
  eighteenth_party: 'Eighteenth Party',
  nineteenth_party: 'Nineteenth Party',
  twentieth_party: 'Twentieth Party',
  draw: 'Draw',
  add: 'Add',
  or_add_field_without_drawing: 'Or add field without drawing',
  text: 'Text',
  number: 'Number',
  signature: 'Signature',
  initials: 'Initials',
  date: 'Date',
  image: 'Image',
  file: 'File',
  select: 'Select',
  checkbox: 'Checkbox',
  multiple: 'Multiple',
  radio: 'Radio',
  cells: 'Cells',
  stamp: 'Stamp',
  payment: 'Payment',
  phone: 'Phone',
  field: 'Field',
  group: 'Group',
  draw_a_text_field_on_the_page_with_a_mouse: 'Draw a text field on the page with a mouse',
  draw_field: 'Draw {field} Field',
  replace: 'Replace',
  uploading_: 'Uploading...',
  add_document: 'Add Document'
}

const zh_CN = {
  search_field: '搜索字段',
  field_not_found: '找不到字段',
  clear: '清除',
  align: '对齐',
  add_all_required_fields_to_continue: '继续前请添加所有必须的字段',
  left: '左',
  right: '右',
  center: '居中',
  description: '描述',
  display_title: '显示标题',
  with_logo: '含Logo',
  unchecked: '未选中',
  equal: '等于',
  not_equal: '不等于',
  contains: '包含',
  does_not_contain: '不包含',
  not_empty: '非空',
  empty: '空',
  select_field_: '选择字段...',
  select_value_: '选择值...',
  remove_condition: '移除条件',
  are_you_sure: '你确定吗？',
  sign_yourself: '自签',
  set_signing_date: '设置签署日期',
  send: '发送',
  remove: '移除',
  edit: '编辑',
  settings: '设置',
  up: '上',
  down: '下',
  checked: '已选中',
  save: '保存',
  cancel: '取消',
  any: '任何',
  drawn: '绘制',
  formula: '公式',
  typed: '已键入',
  draw_field_on_the_document: '在文档上绘制{field}字段',
  click_to_upload: '点击上传',
  or_drag_and_drop_files: '或拖动的文件',
  uploading: '上传中',
  processing_: '处理中...',
  add_pdf_documents_or_images: '添加PDF文档或图片',
  add_documents_or_images: '添加文档或图片',
  required: '必须的',
  default_value: '默认值',
  format: '格式',
  read_only: '只读',
  page: '页',
  draw_new_area: '绘制新区域',
  copy_to_all_pages: '复制到所有页面',
  add_option: '添加选项',
  option: '选项',
  condition: '条件',
  first_party: '第一方',
  second_party: '第二方',
  third_party: '第三方',
  fourth_party: '第四方',
  fifth_party: '第五方',
  sixth_party: '第六方',
  seventh_party: '第七方',
  eighth_party: '第八方',
  ninth_party: '第九方',
  tenth_party: '第十方',
  eleventh_party: '第十一方',
  twelfth_party: '第十二方',
  thirteenth_party: '第十三方',
  fourteenth_party: '第十四方',
  fifteenth_party: '第十五方',
  sixteenth_party: '第十六方',
  seventeenth_party: '第十七方',
  eighteenth_party: '第十八方',
  nineteenth_party: '第十九方',
  twentieth_party: '第二十方',
  draw: '绘制',
  add: '添加',
  or_add_field_without_drawing: '或者不绘制添加字段',
  text: '文本',
  number: '数字',
  signature: '签名',
  initials: '首字母',
  date: '日期',
  image: '图像',
  file: '文件',
  select: '选择',
  checkbox: '复选框',
  multiple: '多个',
  radio: '单选',
  cells: '单元格',
  stamp: '印章',
  payment: '支付',
  phone: '电话',
  field: '字段',
  group: '组',
  draw_a_text_field_on_the_page_with_a_mouse: '用鼠标在页面上绘制文本字段',
  draw_field: '绘制 {field} 字段',
  replace: '替换',
  uploading_: '上传中...',
  add_document: '添加文件'
}

export { en ,zh_CN }
