<template>
  <svg height="40" width="40" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 768.000000 768.000000" xml:space="preserve">


    <g transform="translate(0.000000,768.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path d="M3620 7034 c-547 -50 -1004 -194 -1425 -449 -772 -468 -1300 -1208
-1485 -2083 -68 -323 -64 -195 -68 -2119 l-3 -1743 3201 0 3201 0 -4 1742 c-4
1905 0 1792 -62 2100 -125 624 -446 1207 -913 1660 -495 480 -1119 780 -1817
873 -115 15 -528 28 -625 19z m597 -418 c624 -93 1168 -364 1602 -797 428
-428 696 -961 792 -1574 19 -124 22 -192 26 -592 l5 -453 -751 0 -751 0 0
-225 0 -225 750 0 750 0 0 -855 0 -855 -1525 0 -1525 0 0 1750 0 1750 725 0
725 0 0 220 0 220 -725 0 -725 0 0 824 0 825 48 4 c95 9 479 -2 579 -17z
m-1087 -2821 l0 -2755 -1045 0 -1046 0 4 1538 c3 1690 -1 1572 66 1874 154
701 582 1321 1189 1725 139 92 412 231 563 287 73 27 235 79 267 85 1 1 2
-1239 2 -2754z" />
    </g>
  </svg>

</template>

<script>
export default {
  name: 'ProjectLogo'
}
</script>
